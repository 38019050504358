import React from 'react';
import {ProductColors} from './ProductColors/ProductColors';
import {AddToCartButton} from './AddToCartButton/AddToCartButton';
import {NumberInputSpinner} from './NumberInputSpinner/NumberInputSpinner';
import {
  withTranslations,
  IProvidedTranslationProps,
  withGlobalProps,
  ProvidedGlobalProps,
  withDirection,
} from '../../providers/globalPropsProvider';

import s from './ProductOptions.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {TextOption} from './OptionInputText/TextOption';
import {OptionsDropdown} from './OptionsDropdown/OptionsDropdown';
import {IProductOptionSelectionItem} from '../../types/productDef';
import {ErrorTooltipPlacement, ProductOptionType, UserInputType, defaultQuantity} from '../../constants';
import {selectProductOptionItems, inStock, hasVariantInStock} from '../../services/products/producUtils';
import {isFunction, isArray} from 'util';
import {ErrorTooltip} from './ErrorTooltip/ErrorTooltip';
import {ProductType} from '@wix/wixstores-client-core/dist/src/types';

export interface ProductOptionsProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  errorPlacement?: ErrorTooltipPlacement;
  shouldShowQuantity: boolean;
  shouldShowAddToCartButton: boolean;
}

interface ProductOptionsState {
  submitted: boolean;
}

@withGlobalProps
@withTranslations
@withDirection
export class ProductOptions extends React.Component<ProductOptionsProps, ProductOptionsState> {
  public static defaultProps = {
    errorPlacement: ErrorTooltipPlacement.Left,
  };

  public state = {
    submitted: false,
  };

  public handleUserInput = (inputType: UserInputType, index: number) => (
    data: IProductOptionSelectionItem[] | string
  ): void => {
    const {handleUserInput} = this.props.globals;

    const input = isArray(data) ? data[0] : data;

    if (isFunction(handleUserInput)) {
      handleUserInput(inputType, input, index);
    }

    this.validate();
  };

  private options() {
    const {product} = this.props.globals;

    return selectProductOptionItems(product);
  }

  private renderError(inputType: UserInputType, index: number, message: string): JSX.Element {
    if (!this.state.submitted) {
      return null;
    }

    const {
      errorPlacement,
      globals: {userInputErrors},
    } = this.props;

    if (userInputErrors[inputType][index]) {
      return <ErrorTooltip message={message} placement={errorPlacement} />;
    }

    return null;
  }

  private validate() {
    this.props.globals.validate();
  }

  private renderOptions() {
    const {
      t,
      globals: {userInputs},
    } = this.props;

    return (
      <>
        {this.options().map((options, i) => {
          if (options.optionType === ProductOptionType.COLOR) {
            return (
              <Cell className={s.colors} key={`product-options-${i}`}>
                <ProductColors
                  options={options}
                  onSelect={this.handleUserInput(UserInputType.Selection, i)}
                  selected={userInputs[UserInputType.Selection][i] ? [userInputs[UserInputType.Selection][i]] : []}
                  error={this.renderError(
                    UserInputType.Selection,
                    i,
                    t('SELECT_OPTION_WARNING', {optionTitle: options.title})
                  )}
                />
              </Cell>
            );
          }
          if (options.optionType === ProductOptionType.DROP_DOWN) {
            return (
              <Cell key={`product-options-${i}`}>
                <OptionsDropdown
                  dropdown={options}
                  onSelect={this.handleUserInput(UserInputType.Selection, i)}
                  selected={userInputs[UserInputType.Selection][i]}
                />
                {this.renderError(UserInputType.Selection, i, t('SELECT_OPTION_WARNING', {optionTitle: options.title}))}
              </Cell>
            );
          }
        })}
      </>
    );
  }

  private readonly handleAddToCart = () => {
    const {handleAddToCart} = this.props.globals;
    this.setState({submitted: true});

    this.validate();

    if (isFunction(handleAddToCart)) {
      return handleAddToCart();
    }
  };

  private renderQuantity() {
    const {
      t,
      globals: {product, quantityRange, userInputs},
    } = this.props;

    const shouldShowQuantity = this.props.shouldShowQuantity && product.productType === ProductType.PHYSICAL;
    let error = null;

    if (!shouldShowQuantity) {
      return null;
    }

    if (userInputs[UserInputType.Quantity][0] > quantityRange.max) {
      error = this.renderError(
        UserInputType.Quantity,
        0,
        t('QUANTITY_EXCEEDS_INVENTORY', {inventory: quantityRange.max})
      );
    } else {
      error = this.renderError(
        UserInputType.Quantity,
        0,
        t('QUANTITY_BELOW_MINIMUM_RANGE', {minimum: quantityRange.min})
      );
    }

    return (
      <Cell className={s.quantity}>
        <NumberInputSpinner
          max={quantityRange.max}
          defaultValue={defaultQuantity}
          title={t('QUANTITY_LABEL')}
          onChange={this.handleUserInput(UserInputType.Quantity, 0)}
        />
        {error}
      </Cell>
    );
  }

  private renderCustomText() {
    const {
      t,
      globals: {
        product: {customTextFields},
      },
    } = this.props;

    return customTextFields.map((customTextField, index) => {
      return (
        <Cell key={index}>
          <TextOption
            title={customTextField.title}
            isRequired={customTextField.isMandatory}
            value={''}
            maxLength={customTextField.inputLimit}
            handleOnChange={this.handleUserInput(UserInputType.Text, index)}
          />
          {this.renderError(UserInputType.Text, index, t('REQUIRED_FIELD'))}
        </Cell>
      );
    });
  }

  private renderAddToCartButton() {
    const {
      t,
      globals: {product, selectedVariant, shouldFocusAddToCartButton, onFocusTriggered},
    } = this.props;
    const {price} = selectedVariant || product;

    const productInStock = inStock(product, selectedVariant);
    const disabled = !productInStock || price <= 0;
    let caption = t('ADD_TO_CART_BUTTON');

    if (disabled) {
      caption = price > 0 || !productInStock ? t('PRODUCT_OUT_OF_STOCK_BUTTON') : t('ADD_TO_CART_BUTTON_PRICE_ZERO');
    }

    return (
      <Cell>
        <AddToCartButton
          onFocusTriggered={onFocusTriggered}
          disabled={disabled}
          text={caption}
          onSubmit={this.handleAddToCart}
          isFocused={shouldFocusAddToCartButton}
        />
      </Cell>
    );
  }

  public render() {
    const {
      shouldShowAddToCartButton,
      globals: {
        product,
        product: {customTextFields},
      },
    } = this.props;

    const shouldShowInputs = inStock(product) || hasVariantInStock(product);

    return (
      <div data-hook="product-options" className={s.productOptions}>
        {shouldShowInputs && (
          <div data-hook="product-options-inputs">
            {this.renderOptions()}
            {customTextFields && this.renderCustomText()}
            {this.renderQuantity()}
          </div>
        )}
        {shouldShowAddToCartButton && this.renderAddToCartButton()}
      </div>
    );
  }
}

import React from 'react';
import {IProductOptionsItem, IProductOptionSelectionItem} from '../../../types/productDef';
import s from './ProductColor.scss';
import {ColorSampleGroup} from '@wix/wixstores-client-common-components/dist/src/ColorSampleGroup/ColorSampleGroup';

export interface ProductColorsProps {
  options: IProductOptionsItem;
  allowMultiple?: boolean;
  error?: JSX.Element;
  onSelect?: Function;
  selected?: IProductOptionSelectionItem[];
}

export class ProductColors extends React.Component<ProductColorsProps> {
  public static defaultProps = {
    allowMultiple: false,
    selected: [],
  };

  public readonly onSelect = (selected: IProductOptionSelectionItem[]): void => {
    if (this.props.onSelect) {
      this.props.onSelect(selected);
    }
  };

  public readonly renderDescription = (): string => {
    const {selected} = this.props;

    if (!selected.length) {
      return null;
    }

    return `: ${selected.map((selection: IProductOptionSelectionItem) => selection.description).join(', ')}`;
  };

  public render() {
    const {
      options: {title, selections},
      allowMultiple,
      error,
      selected,
    } = this.props;

    return (
      <section>
        <div data-hook="product-colors-title" className={s.description}>
          {title}
          {this.renderDescription()}
        </div>
        <div className={s.actionable}>
          {error || null}
          <ColorSampleGroup
            allowMultiple={allowMultiple}
            onSelect={this.onSelect}
            options={selections}
            selected={selected}
          />
        </div>
      </section>
    );
  }
}

import React from 'react';
import {InfoSectionProps} from '../../InfoSectionLayout';
import {InfoSectionDescription} from '../../InfoSectionDescription/InfoSectionDescription';
import s from './TabsInfoSection.scss';
import {Cell} from '../../../Layouts/Cell/Cell';
import * as Icon from '../../../../icons/dist';
import _ from 'lodash';
import {animate} from './TabsInfoSectionUtils';
import {TabWithRef} from './Tab/Tab';
import {keyboardEvents, TabsDirection} from '../../../../constants';
import {IIndicatorStyle, InfoSectionEvent, IRefWithIndex} from '../../../../types/app-types';
import {IProvidedTranslationProps, withTranslations} from '../../../../providers/globalPropsProvider';
import classNames from 'classnames';
import {IWixStyleFont} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {IProductAdditionalInfo} from '../../../../types/productDef';

export enum DataHook {
  TabsInfoSection = 'tabs-info-section',
  TabsHeader = 'tabs-info-section-header',
  Body = 'tabs-info-section-body',
  ArrowLeft = 'tabs-arrow-left',
  ArrowRight = 'tabs-arrow-right',
  Indicator = 'tabs-indicator',
}

interface TabsInfoSectionState {
  currentActiveTabIndex: number;
  indicatorStyle: IIndicatorStyle;
  showLeftScroll: boolean;
  showRightScroll: boolean;
  tabs: IProductAdditionalInfo[];
}
export interface TabsInfoSectionProps extends InfoSectionProps, IProvidedTranslationProps {
  handleBIEvent(BIEvent: InfoSectionEvent): void;
  tabFontStyle: IWixStyleFont;
  direction: TabsDirection;
  isRTL?: boolean;
}

@withTranslations
export class TabsInfoSection extends React.Component<TabsInfoSectionProps, TabsInfoSectionState> {
  private readonly tabsHeaderRef = React.createRef<HTMLUListElement>();
  private tabsRefArray: IRefWithIndex[] = [];
  private readonly arrowStyle = {width: 45};

  constructor(props) {
    super(props);
    this.setTabsRefArray(props);
  }

  public componentWillReceiveProps(props) {
    this.setTabsRefArray(props);
  }

  private setTabsRefArray(props) {
    this.tabsRefArray = [];
    for (let index = 0; index < props.infoSectionItems.length; index++) {
      this.tabsRefArray.push({index, ref: React.createRef<HTMLLIElement>()});
    }
  }

  public state = {
    showLeftScroll: false,
    showRightScroll: false,
    currentActiveTabIndex: this.props.direction === TabsDirection.start ? 0 : this.props.infoSectionItems.length - 1,
    indicatorStyle: {width: 0, left: 0},
    tabs: [],
  };

  public static getDerivedStateFromProps(props: TabsInfoSectionProps) {
    const {infoSectionItems, direction} = props;
    const tabs = direction === TabsDirection.end ? [...infoSectionItems].reverse() : [...infoSectionItems];
    return {
      tabs,
    };
  }

  public componentDidMount(): void {
    const {currentActiveTabIndex} = this.state;
    if (this.props.infoSectionItems.length > 0) {
      setTimeout(() => {
        this.updateIndicator(this.getRefFromTabsRefArray(currentActiveTabIndex));
      }, 0);
      this.scrollSelectedTabIntoView(this.getRefFromTabsRefArray(currentActiveTabIndex));
      this.updateScrollArrowButtons();
    }
    this.handleTabsScroll();
  }

  public componentDidUpdate(prevProps: TabsInfoSectionProps, prevState: TabsInfoSectionState) {
    if (prevState !== this.state || prevProps !== this.props) {
      this.updateScrollArrowButtons();
    }
    /* istanbul ignore next: js ui animation test in puppeteer */
    if (prevProps.tabFontStyle !== this.props.tabFontStyle) {
      const {currentActiveTabIndex} = this.state;
      if (this.props.infoSectionItems.length > 0) {
        this.updateIndicator(this.getRefFromTabsRefArray(currentActiveTabIndex));
      }
    }
    /* istanbul ignore next: js ui animation test in puppeteer */
    if (prevProps.direction !== this.props.direction) {
      const currentActiveTabIndex =
        this.props.direction === TabsDirection.start ? 0 : this.props.infoSectionItems.length - 1;
      this.setState({currentActiveTabIndex});
      if (this.props.infoSectionItems.length > 0) {
        this.updateIndicator(this.getRefFromTabsRefArray(currentActiveTabIndex));
        this.scrollSelectedTabIntoView(this.getRefFromTabsRefArray(currentActiveTabIndex));
      }
    }
  }

  private readonly getRefFromTabsRefArray = (index: number) => {
    return this.tabsRefArray.find(refWithIndexObj => refWithIndexObj.index === index).ref;
  };

  private readonly handleTabsScroll = _.debounce(() => {
    this.updateScrollArrowButtons();
  }, 0);

  private readonly updateScrollArrowButtons = () => {
    const {direction} = this.props;
    const {scrollWidth, clientWidth, scrollLeft} = this.tabsHeaderRef.current;
    let showLeftScroll;
    let showRightScroll;
    if (direction === TabsDirection.start) {
      showLeftScroll = scrollLeft > 0;
      showRightScroll = scrollWidth > clientWidth + scrollLeft;
    } else {
      showLeftScroll = 0 < scrollLeft && scrollLeft < scrollWidth;
      showRightScroll = scrollLeft + clientWidth < scrollWidth;
    }

    if (showLeftScroll !== this.state.showLeftScroll || showRightScroll !== this.state.showRightScroll) {
      this.setState({showLeftScroll, showRightScroll});
    }
  };

  private readonly handleLeftScrollClick = () => {
    this.scrollTabs(-this.tabsHeaderRef.current.clientWidth);
  };

  private readonly handleRightScrollClick = () => {
    this.scrollTabs(this.tabsHeaderRef.current.clientWidth);
  };

  /* istanbul ignore next: js ui animation test in puppeteer */
  private readonly onKeypressLeftScroll = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      this.handleLeftScrollClick();
    }
  };

  /* istanbul ignore next: js ui animation test in puppeteer */
  private readonly onKeypressRightScroll = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      this.handleRightScrollClick();
    }
  };

  private readonly scrollTabs = (clientWidth: number) => {
    if (this.tabsHeaderRef.current !== null) {
      const nextScrollLeft = this.tabsHeaderRef.current.scrollLeft + clientWidth - this.arrowStyle.width * 2;
      this.scroll(nextScrollLeft);
    }
  };

  private readonly scroll = (numberToScroll: number) => {
    animate('scrollLeft', this.tabsHeaderRef.current, numberToScroll);
  };

  private readonly getTabsMeta = (tabRef?: React.RefObject<HTMLLIElement>) => {
    const rect: ClientRect = this.tabsHeaderRef.current.getBoundingClientRect();
    const tabMeta: ClientRect = tabRef.current.getBoundingClientRect();
    const {clientWidth, scrollLeft, scrollWidth} = this.tabsHeaderRef.current;
    const tabsMeta = {
      clientWidth,
      scrollLeft,
      scrollWidth,
      left: rect.left,
      right: rect.right,
    };

    return {tabsMeta, tabMeta};
  };

  private readonly updateIndicator = (tabRef?: React.RefObject<HTMLLIElement>) => {
    const {tabsMeta, tabMeta} = this.getTabsMeta(tabRef);
    let left = 0;
    if (tabMeta && tabsMeta) {
      const correction = tabsMeta.scrollLeft;
      left = Math.round(tabMeta.left - tabsMeta.left + correction);
    }

    const indicatorStyle = {
      left,
      width: tabMeta ? Math.round(tabMeta.width) : 0,
    };
    if (
      (indicatorStyle.left !== this.state.indicatorStyle.left ||
        indicatorStyle.width !== this.state.indicatorStyle.width) &&
      !isNaN(indicatorStyle.left) &&
      !isNaN(indicatorStyle.width)
    ) {
      this.setState({indicatorStyle});
    }
  };

  /* istanbul ignore next: js ui animation test in puppeteer */
  private readonly scrollSelectedTabIntoView = (tabRef?: React.RefObject<HTMLLIElement>) => {
    const {tabsMeta, tabMeta} = this.getTabsMeta(tabRef);

    if (!tabMeta || !tabsMeta) {
      return;
    }
    if (tabMeta.left < tabsMeta.left) {
      const nextScrollLeft = tabsMeta.scrollLeft + (tabMeta.left - tabsMeta.left) - this.arrowStyle.width;
      this.scroll(nextScrollLeft);
    } else if (tabMeta.right > tabsMeta.right) {
      const nextScrollLeft = tabsMeta.scrollLeft + (tabMeta.right - tabsMeta.right) + this.arrowStyle.width;
      this.scroll(nextScrollLeft);
    }
  };

  public handelToggleActive = (index: number) => {
    const {infoSectionItems, handleBIEvent} = this.props;
    if (index < infoSectionItems.length && index >= 0) {
      this.setState({currentActiveTabIndex: index});
      const currentTab = this.getRefFromTabsRefArray(index);
      currentTab.current.focus();
      this.updateIndicator(currentTab);
      this.scrollSelectedTabIntoView(currentTab);
      handleBIEvent({index, type: 'tabs'});
    }
  };

  private readonly getLeftArrow = () => {
    const {t} = this.props;
    const {showLeftScroll} = this.state;
    const iconContainerClass = classNames(s.iconContainer, s.leftArrowButton);
    const btnClass = classNames(s.icon);
    return (
      showLeftScroll && (
        <div style={this.arrowStyle} className={iconContainerClass}>
          <button
            onKeyPress={this.onKeypressLeftScroll}
            className={btnClass}
            onClick={this.handleLeftScrollClick}
            data-hook={DataHook.ArrowLeft}
            aria-label={t('GALLERY_PREVIOUS')}>
            <Icon.ArrowLeft />
          </button>
        </div>
      )
    );
  };

  private readonly getRightArrow = () => {
    const {t} = this.props;
    const {showRightScroll} = this.state;
    const iconContainerClass = classNames(s.iconContainer, s.rightArrowButton);
    const btnClass = classNames(s.icon);
    return (
      showRightScroll && (
        <div style={this.arrowStyle} className={iconContainerClass}>
          <button
            onKeyPress={this.onKeypressRightScroll}
            className={btnClass}
            onClick={this.handleRightScrollClick}
            data-hook={DataHook.ArrowRight}
            aria-label={t('GALLERY_NEXT')}>
            <Icon.ArrowRight />
          </button>
        </div>
      )
    );
  };

  private readonly getTabsHeader = () => {
    const {currentActiveTabIndex, tabs} = this.state;

    return tabs.map((infoItem, index) => {
      return (
        <TabWithRef
          ref={this.getRefFromTabsRefArray(index)}
          key={index}
          index={index}
          title={infoItem.title}
          isActive={currentActiveTabIndex === index}
          handelToggleActive={this.handelToggleActive}
        />
      );
    });
  };

  private readonly getIndicator = () => {
    const {infoSectionItems} = this.props;
    const {indicatorStyle} = this.state;
    return (
      infoSectionItems.length >= 1 && (
        <div className={s.indicator} style={indicatorStyle} data-hook={DataHook.Indicator} />
      )
    );
  };

  private readonly getBody = () => {
    const {isRTL} = this.props;
    const {currentActiveTabIndex, tabs} = this.state;
    const currentTab = tabs[currentActiveTabIndex];
    return (
      tabs.length >= 1 && (
        <Cell
          key={currentActiveTabIndex}
          className={s.description}
          data-hook={DataHook.Body}
          dir={isRTL ? 'rtl' : 'ltr'}>
          <InfoSectionDescription description={currentTab.description} index={currentTab.index} />
        </Cell>
      )
    );
  };

  public render() {
    return (
      <div data-hook={DataHook.TabsInfoSection}>
        <div className={s.tabsHeaderContainer}>
          <div className={s.tabsContainer}>
            {this.getLeftArrow()}
            <ul
              className={s.tabs}
              ref={this.tabsHeaderRef}
              onScroll={this.handleTabsScroll}
              data-hook={DataHook.TabsHeader}>
              {this.getTabsHeader()}
              {this.getIndicator()}
            </ul>
            {this.getRightArrow()}
          </div>
          {this.getBody()}
        </div>
      </div>
    );
  }
}
